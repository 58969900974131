import { Card, Stack, Typography } from '@mui/joy';
import React, { useContext, useEffect } from 'react';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useParams } from 'react-router-dom';
import BackButton from '../molecules/BackButton';
import Loader from '../molecules/Loader';
import { useAppAuth } from '../contexts/auth-context';
import TechnicalSurveyActionsCard from '../molecules/projects/TechnicalSurveyActionsCard';
import { DocumentCards } from '../molecules/documents/DocumentCard';

import { t } from 'i18next';
import ImagesCard from '../molecules/projects/ImagesCard';

export function ProjectTechnicalDataPage() {
    const { authenticatedCall } = useAppAuth();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);
    const project = projectState.project;

    // filter documents by type
    const pdfDocuments =
        project && project.documents
            ? project.documents.filter(
                  (doc) => doc.type && doc.type === 'project-pdf' && doc.mimeType && doc.mimeType.includes('pdf'),
              )
            : [];

    const imageDocuments =
        project && project.documents
            ? project.documents.filter(
                  (doc) =>
                      doc.type &&
                      doc.type === 'project-picture' &&
                      doc.mimeType &&
                      (doc.mimeType.includes('jpg') || doc.mimeType.includes('jpeg') || doc.mimeType.includes('png')),
              )
            : [];

    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="technical-data-page"
            >
                <BackButton path={`/projects/${projectState?.project?.id}`} label="projects.generic_data.back" />
                {projectState.project && projectState.project.id === id ? (
                    <>
                        <TechnicalSurveyActionsCard project={projectState.project} />
                        <ImagesCard projectId={id} documents={imageDocuments} />
                        <Card variant="plain">
                            <Typography level="h4">{t('projects.technical_data.pdf_documents')}</Typography>
                            <Typography level="body-sm">
                                {t('projects.technical_data.pdf_documents_subtitle')}
                            </Typography>

                            <DocumentCards documents={pdfDocuments} />
                        </Card>
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </PageTemplate>
    );
}
