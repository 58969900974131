import { Card, IconButton, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo, useState } from 'react';

import { projectActions } from '../../../core/project/service';
import DarkCloseIcon from '../../atoms/icons/DarkCloseIcon';
import { useAppAuth } from '../../contexts/auth-context';
import { ProjectsContext } from '../../contexts/project-context';
import FloatingLabelInput from '../forms/FloatingLabelInput';
import Button from '../Button';
import FileInput from '../forms/FileInput';
import RadioInput from '../forms/RadioInput';

interface TypeOption {
    label: string;
    value: string;
}

export interface DocumentDto {
    id?: string;
    name: string;
    title: string;
    fileKey?: string;
    size: number;
    mimeType: string;
    type: string;
    parentEntityId?: string;
    parentEntityName: string;
    projectId: string;
    createdAt?: Date;
    updatedAt?: Date;
    body: string;
}

export default function DocumentForm({
    projectId,
    parentEntityName,
    parentEntityId,
    type,
    types,
    showTitleInput,
    showSubmitButton,
    callback,
}: {
    projectId: string;
    parentEntityName: string;
    parentEntityId: string;
    type: string;
    types: TypeOption[];
    showTitleInput: boolean;
    showSubmitButton: boolean;
    callback?: (documents: DocumentDto[]) => void;
}) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;

    const initialDocumentsState = [
        {
            name: '',
            title: '',
            size: 0,
            mimeType: '',
            type: types && types.length > 0 ? '' : type, // if only one type, set it as default, if multiple types, let the user choose
            parentEntityId: parentEntityId,
            parentEntityName: parentEntityName,
            projectId: projectId,
            body: '',
        },
    ];

    const [localDocuments, setLocalDocuments] = useState<DocumentDto[]>(initialDocumentsState);
    const [uploading, setUploading] = useState(false);
    const [files, setFiles] = useState<(File | null)[]>([]);

    const setValues = (values: Array<string>, index: number, keys: Array<string>) => {
        const newFile = { ...localDocuments[index], ...Object.fromEntries(keys.map((k, i) => [k, values[i]])) };
        const updatedDocuments = [...localDocuments.slice(0, index), newFile, ...localDocuments.slice(index + 1)];
        setLocalDocuments(updatedDocuments);
        callback && callback(updatedDocuments);
    };

    const handleRemoveDocument = (index: number) => {
        setLocalDocuments(localDocuments.filter((_, i) => i !== index));
        setFiles(files.filter((_, i) => i !== index));
    };

    const isStateValid = useMemo(() => !localDocuments.some((f) => !f.title || !f.body), [localDocuments]);

    const submit = async () => {
        setUploading(true);
        try {
            await authenticatedCall(async (accessToken) => {
                dispatch(projectActions.createDocuments(accessToken, localDocuments as DocumentDto[]));
            });
            if (callback) {
                callback(localDocuments);
            }

            setLocalDocuments(initialDocumentsState);
            setFiles(files.map(() => null));
        } catch (e: any) {
            console.error(e.error);
        } finally {
            setUploading(false);
        }
    };

    const convertAndSaveFile = (file: File | null, callback: (f: string | null) => void) => {
        if (file) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                callback(reader.result as string);
            };
            reader.onerror = function (error) {
                console.error('Error: ', error);
                return null;
            };
        } else {
            callback(null);
        }
    };

    return localDocuments.map((_, index) => (
        /* display index value */
        <Card variant="plain" key={`${index}`}>
            <Stack direction="row" justifyContent="space-between">
                <Typography level="h4" alignSelf="center"></Typography>
                {localDocuments.length !== 1 && (
                    <IconButton
                        color="neutral"
                        onClick={() => {
                            handleRemoveDocument(index);
                        }}
                    >
                        <DarkCloseIcon />
                    </IconButton>
                )}
            </Stack>
            {showTitleInput && (
                <FloatingLabelInput
                    label={t('file_management.title')}
                    value={localDocuments[index].title}
                    onChange={(e) => setValues([e.target.value], index, ['title'])}
                    data-test-id={`title-input-${index}`}
                />
            )}

            {types && types.length > 1 && (
                <RadioInput
                    selectedValue={localDocuments[index].type}
                    choices={types}
                    onChange={(value) => setValues([value.toString()], index, ['type'])}
                />
            )}
            <FileInput
                onChange={(file) => {
                    convertAndSaveFile(file, (f) => {
                        const mimeType = file?.type ?? '';
                        const body = f ?? '';
                        const name = file?.name ?? '';
                        const size = file?.size ?? 0;
                        setValues([mimeType, body, name, size.toString()], index, ['mimeType', 'body', 'name', 'size']);
                    });
                }}
                dataTestId={`document-input-${index}`}
                file={files[index] || null}
                setFile={(file) => {
                    setFiles((prevFiles) => {
                        const newFiles = [...prevFiles];
                        newFiles[index] = file;
                        return newFiles;
                    });
                }}
            />
            {index + 1 === localDocuments.length && (
                <>
                    {/* add additional document button */}
                    <Button
                        onClick={() => {
                            setLocalDocuments([
                                ...localDocuments,
                                {
                                    name: '',
                                    title: '',
                                    size: 0,
                                    mimeType: '',
                                    type: type,
                                    parentEntityId: parentEntityId,
                                    parentEntityName: parentEntityName,
                                    projectId: projectId,
                                    body: '',
                                },
                            ]);
                            window.scrollTo(0, document.body.scrollHeight);
                        }}
                        disabled={uploading}
                        variant="outlined"
                        color="neutral"
                        label={t('file_management.additional_document')}
                        data-test-id="additionnal-file"
                    />
                    {showSubmitButton && (
                        <Button
                            onClick={submit}
                            disabled={!isStateValid}
                            loading={uploading}
                            label={t('file_management.submit', { number: localDocuments.length })}
                            data-test-id="submit"
                        />
                    )}
                </>
            )}
        </Card>
    ));
}
