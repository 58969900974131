const DownloadIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6699 7.87034L7.99998 11.5402" stroke="#17181A" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M4.33012 7.87034L8 11.5402" stroke="#17181A" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M8 1.44804L8 11.5402" stroke="#17181A" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M1.28697 16.1966H14.713" stroke="#17181A" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M1.28697 16.1989L1.28697 11.5425" stroke="#17181A" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M14.713 16.1966L14.713 11.5402" stroke="#17181A" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
};

export default DownloadIcon;
