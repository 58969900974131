import { useContext, useEffect, useState } from 'react';
import { Box, Card, Grid, Modal, ModalClose, ModalDialog, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { theme } from 'src/ui/theme';
import ModalHeader from '../ModalHeader';
import { projectActions } from 'src/core/project/service';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import { Document } from 'src/core/project/domain';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import Loader from '../Loader';

function ImageModal({ src, name, open, setOpen }: { src: string; name: string; open: boolean; setOpen: Function }) {
    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <ModalDialog layout="fullscreen" color="secondary" variant="solid" sx={{ padding: 0 }}>
                <ModalHeader label={name} />
                <Stack height="100%" justifyContent="center" sx={{ overflow: 'scroll' }}>
                    <ModalClose />
                    <img alt={name} src={src} style={{ height: '100%', objectFit: 'contain' }} />
                </Stack>
            </ModalDialog>
        </Modal>
    );
}

function ImageCard({ projectId, document }: { projectId: string; document: Document }) {
    const [open, setOpen] = useState<boolean>(false);
    const { projectState } = useContext(ProjectsContext)!;
    var cardStyle = {
        height: '30vw',
    };

    return (
        <Card
            style={cardStyle}
            orientation="vertical"
            size="md"
            variant="solid"
            sx={{
                backgroundColor: theme.palette.grey[100],
            }}
        >
            {projectState && projectState.files[document.fileKey] && (
                <>
                    <Typography level="body-lg" sx={{ color: theme.palette.neutral[900], fontWeight: 700 }}>
                        {document.name}
                    </Typography>
                    <img
                        src={projectState.files[document.fileKey]!}
                        alt={document.name}
                        onClick={() => {
                            setOpen(true);
                        }}
                        style={{ maxHeight: '80%', objectFit: 'contain' }}
                    />

                    <ImageModal
                        src={projectState.files[document.fileKey]!}
                        name={document.name}
                        open={open}
                        setOpen={setOpen}
                    />
                </>
            )}
        </Card>
    );
}

export default function ImagesCard({ projectId, documents }: { projectId: string; documents: Document[] }) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { dispatch } = useContext(ProjectsContext)!;
    const [loading, setLoading] = useState(true);
    const { projectState } = useContext(ProjectsContext)!;

    useEffect(() => {
        const loadDocuments = async () => {
            for (const document of documents) {
                await authenticatedCall(async (accessToken) => {
                    console.log('calling retrieveFileByS3Key');
                    await dispatch(projectActions.retrieveFileByS3Key(accessToken, projectId, document.fileKey));
                });
            }
            setLoading(false); // Set loading to false only after all are complete
        };

        loadDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return !loading ? (
        <Card variant="plain">
            <Typography level="h4">{t('projects.technical_data.pictures')}</Typography>
            <Typography level="body-sm">{t('projects.technical_data.pictures_subtitle')}</Typography>

            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ flexGrow: 1 }}>
                {documents &&
                    documents.map((document, index) => (
                        <Grid alignItems="stretch" xs={12} sm={6} md={4} key={index}>
                            {projectState.files[document.fileKey] && (
                                <Box>
                                    <ImageCard document={document} projectId={projectId} key={`picture-${index}`} />
                                </Box>
                            )}
                        </Grid>
                    ))}
            </Grid>
        </Card>
    ) : (
        <Loader />
    );
}
