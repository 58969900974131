import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useAppAuth } from '../contexts/auth-context';

import BackButton from '../molecules/BackButton';
import { Card, Grid, Stack, Typography } from '@mui/joy';
import { t } from 'i18next';
import BillingForm from '../molecules/billing/BillingForm';
import { Billing, Project, PurchaseOrder } from 'src/core/project/domain';
import { getUserLang } from '../i18n';
import formatDate from 'src/utils/formatDate';
import { theme } from '../theme';
import Loader from '../molecules/Loader';
import AliceCarousel from 'react-alice-carousel';
import BillingCard from '../molecules/billing/BillingCard';

export function NewBillingsPage() {
    const { authenticatedCall } = useAppAuth();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const { id, purchaseOrderId } = useParams();
    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);

    const [showForm, setShowForm] = useState(false);

    // init project
    const project: Project | null = projectState.project;
    const projectId: string = project && project.id ? project.id : '';

    const purchaseOrder: PurchaseOrder = project?.purchaseOrders?.filter((p) => p.id === purchaseOrderId)[0]!;
    console.log(purchaseOrder);

    const billings: Array<Billing> = project?.billings.filter((b) => b.purchaseOrder?.id === purchaseOrderId) ?? [];
    const rejectedBillings = billings?.filter((billing) => billing?.status === 'rejected');

    let totalBillingsAmount: number = 0;
    for (const billing of billings) {
        if (billing && billing.amount) {
            totalBillingsAmount += Number(billing.amount);
        }
    }

    let totalPurchaseOrdersAmount: number = 1;

    let totalRejectedBillingsAmount: number = 0;
    for (const billing of rejectedBillings) {
        if (billing && billing.amount) {
            totalRejectedBillingsAmount += Number(billing.amount);
        }
    }

    useEffect(() => {
        // show the form when billings amount is less than the sum of purchase orders amount
        let billedAmount = totalBillingsAmount - totalRejectedBillingsAmount;
        let displayForm = false;
        if (totalPurchaseOrdersAmount !== 0 && billedAmount < totalPurchaseOrdersAmount) {
            displayForm = true; // remaining amount to be billed
        }

        setShowForm(displayForm);
    }, [project, totalBillingsAmount, totalRejectedBillingsAmount, totalPurchaseOrdersAmount]);
    // const latestDoc = project?.documents
    //     ?.filter((d) => d.parentEntityId === purchaseOrderId)
    //     .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]!;
    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="billings-page"
            >
                <BackButton path={`/projects/${projectId}/billings`} label={t(`projects.generic_data.back`)} />
                {project && project.id ? (
                    <>
                        <Card variant="plain">
                            <Typography level="h4" alignSelf="center">
                                {t('billing.new_billing')}
                            </Typography>
                            <Card
                                variant={'plain'}
                                color="grey"
                                sx={{ margin: 1, backgroundColor: theme.palette.grey[100] }}
                            >
                                <Stack spacing={1}>
                                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                        <Grid xs="auto">
                                            <Typography level="title-lg">
                                                {t('billing.purchase_order.title')}
                                            </Typography>
                                            <Typography level="title-lg">{purchaseOrder.number}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                        <Grid xs={6}>
                                            <Typography level="body-md">{t('billing.date')}</Typography>
                                            <Typography level="title-lg">
                                                {formatDate(
                                                    purchaseOrder.date,
                                                    false,
                                                    false,
                                                    getUserLang(),
                                                    projectState.project?.address.countryCode,
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid xs="auto">
                                            <Typography level="body-md">{t('quote.amount_short')}</Typography>
                                            <Typography level="title-lg">{purchaseOrder.amount} €</Typography>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Card>
                        </Card>
                        {showForm && (
                            <BillingForm
                                projectId={projectId}
                                purchaseOrderId={purchaseOrderId}
                                maxAmount={
                                    totalPurchaseOrdersAmount - (totalBillingsAmount - totalRejectedBillingsAmount)
                                }
                                callback={() => {
                                    authenticatedCall(async (accessToken) => {
                                        dispatch(projectActions.loadOne(accessToken, projectId));
                                    });
                                }}
                            />
                        )}
                        {billings && billings.length !== 0 && (
                            <AliceCarousel
                                disableButtonsControls={true}
                                responsive={{ 0: { items: 1 }, 568: { items: 2 }, 1024: { items: 3 } }}
                                controlsStrategy="responsive"
                                items={billings.map((billing, index) => (
                                    <BillingCard item={billing} small showDetailsButton />
                                ))}
                            />
                        )}

                        {rejectedBillings && rejectedBillings.length !== 0 && (
                            <AliceCarousel
                                disableButtonsControls={true}
                                responsive={{ 0: { items: 1 }, 568: { items: 2 }, 1024: { items: 3 } }}
                                controlsStrategy="responsive"
                                items={rejectedBillings.map((billing, index) => (
                                    <BillingCard item={billing} small />
                                ))}
                            />
                        )}
                    </>
                ) : (
                    <Loader />
                )}
            </Stack>
        </PageTemplate>
    );
}
