import { Card, Grid, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Project, PurchaseOrder } from 'src/core/project/domain';
//import DocumentCard from '../documents/DocumentCard';
import { PurchaseOrderResume } from './PurchaseOrderResume';

export function PurchaseOrderCards({ project }: { project: Project | null }) {
    const { t } = useTranslation();

    const poDocuments = project?.documents.filter((doc) => doc.type === 'purchase-order');
    if (poDocuments && poDocuments.length <= 0) {
        return null;
    }

    return (
        <>
            {project?.purchaseOrders?.map((po: PurchaseOrder) => (
                <Card variant="plain" key={po.id}>
                    <Typography level="h4">{t('billing.purchase_order.title')}</Typography>
                    <Grid container spacing={0} sx={{ flexGrow: 1 }}>
                        <Typography color="grey" level="title-sm">
                            {t('billing.purchase_order.amount', { amount: po.amount })}
                        </Typography>
                    </Grid>

                    {(() => {
                        const latestDoc = poDocuments
                            ?.filter((d) => d.parentEntityId === po.id)
                            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];

                        // return latestDoc ? <DocumentCard key={latestDoc.id} item={latestDoc}></DocumentCard> : null;
                        return latestDoc ? (
                            <PurchaseOrderResume item={latestDoc} purchaseOrder={po}></PurchaseOrderResume>
                        ) : null;
                    })()}
                </Card>
            ))}
        </>
    );
}
