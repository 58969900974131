import { Card, Grid, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Document } from 'src/core/project/domain';
import Button from '../Button';
import { useContext } from 'react';
import { ProjectsContext } from 'src/ui/contexts/project-context';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import { BackendDataSourceContext } from 'src/ui/contexts/backend-data-source-context';
import PdfIcon from 'src/ui/atoms/icons/PdfIcon';
import ImageIcon from 'src/ui/atoms/icons/ImageIcon';
import EyeIcon from 'src/ui/atoms/icons/EyeIcon';
import previewFile from 'src/utils/previewFile';

function DocumentSection({ item }: { item: Document }) {
    const { projectState } = useContext(ProjectsContext)!;
    const { authenticatedCall } = useAppAuth();

    const dataSourceBackend = useContext(BackendDataSourceContext)!;

    const onClick = () => {
        const project = projectState.project;
        if (item && item.id) {
            authenticatedCall(async (accessToken) => {
                previewFile(project, item.fileKey, item.title, dataSourceBackend, accessToken);
            });
        }
    };

    function getIconForMimeType(mimeType: string | undefined) {
        if (!mimeType) return <EyeIcon />;
        const type = mimeType.toLowerCase();
        if (type.includes('pdf')) return <PdfIcon />;
        if (type.includes('image')) return <ImageIcon />;
        return <EyeIcon />;
    }

    // render pdf icon when pdf
    return (
        <Stack>
            <Typography color="grey" level="title-sm"></Typography>
            <Button
                label={item.title}
                color="grey"
                variant="soft"
                startDecorator={
                    <Typography color="grey" level="title-sm">
                        {getIconForMimeType(item.mimeType)}
                    </Typography>
                }
                onClick={onClick}
            />
        </Stack>
    );
}

export function DocumentCards({ documents }: { documents: Array<Document> }) {
    useTranslation();

    return (
        <>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ flexGrow: 1 }}>
                {documents &&
                    documents.map((doc, index) => (
                        <Grid xs={12} sm={6} md={4} key={index}>
                            <DocumentCard item={doc} key={`${doc.fileKey}`} />
                        </Grid>
                    ))}
            </Grid>
        </>
    );
}

export default function DocumentCard({ item, small }: { item: Document; small?: boolean }) {
    return (
        <Card variant={small ? 'outlined' : 'plain'} sx={{ margin: 1 }}>
            {!small && <DocumentSection item={item} />}
        </Card>
    );
}
