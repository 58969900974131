import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectsContext } from '../contexts/project-context';
import { projectActions } from 'src/core/project/service';
import { PageTemplate } from '../layout/PageTemplate';
import { useAppAuth } from '../contexts/auth-context';

import BackButton from '../molecules/BackButton';
import { Stack } from '@mui/joy';
import { t } from 'i18next';
import { Billings } from '../molecules/billing/Billings';

export function BillingsPage() {
    const { authenticatedCall } = useAppAuth();
    const { projectState, dispatch } = useContext(ProjectsContext)!;
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            authenticatedCall(async (accessToken) => dispatch(projectActions.loadOne(accessToken, id)));
        }
    }, [dispatch, id, authenticatedCall]);
    const project = projectState.project;
    const projectId = project?.id || '';
    return (
        <PageTemplate greyBackground>
            <Stack
                sx={{ height: '100%', paddingX: '20px', paddingY: '40px', gap: '16px' }}
                data-test-id="billings-page"
            >
                <BackButton path={`/projects/${projectId}`} label={t(`projects.generic_data.back`)} />
                <Billings></Billings>
            </Stack>
        </PageTemplate>
    );
}
