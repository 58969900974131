import { Project } from 'src/core/project/domain';
import TwoActionsCard from '../TwoActionsCard';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/joy';
import { ReactNode, useContext, useMemo, useState } from 'react';
import formatDate from '../../../utils/formatDate';
import Toaster from '../Toaster';
import getStatusKey from 'src/utils/getStatusKey';
import DateModal from '../modal/DateModal';
import { useNavigate } from 'react-router-dom';
import { getUserLang } from 'src/ui/i18n';
import { ProjectsContext } from 'src/ui/contexts/project-context';

export default function InstallationActionsCard({ project }: { project: Project }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarText] = useState<string>(t('installation.error_on_form_link'));
    const navigate = useNavigate();
    const { projectState } = useContext(ProjectsContext)!;

    const statusKey = useMemo(() => {
        let statusKey = getStatusKey(project);
        return statusKey;
    }, [project]);

    let primaryLabel: string | undefined;
    let secondaryLabel: string | undefined;
    let title: string | ReactNode | undefined;
    let subtitle: string | ReactNode | undefined;
    let onPrimaryButtonClick: Function | undefined;
    let onSecondaryButtonClick: Function | undefined;
    let primaryRightArrow: boolean | undefined;
    let secondaryRightArrow: boolean | undefined;
    let primaryActionDataTestId: string | undefined = `${statusKey}_action`;
    let secondaryActionDataTestId: string = 'secondary-action';
    let color: 'primary' | 'danger' | undefined;

    if (
        [
            'delivery',
            'installation',
            'shipping_in_progress',
            'installation_to_plan',
            'installation_planned',
            'waiting_for_pre_installation_form',
            'ready_to_install',
        ].find((e) => e === statusKey)
    ) {
        title = t(`projects.lifecycle.card_title.${statusKey}`);
        subtitle = `installation.actions_card_subtitle.${statusKey}`;

        if (statusKey === 'installation') {
            primaryLabel = 'installation.actions_card_buttons.plan_installation_date';

            onPrimaryButtonClick = () => {
                setOpen(true);
            };
        } else if (statusKey === 'shipping_in_progress') {
            primaryLabel = 'installation.actions_card_buttons.view_delivery_status';

            onPrimaryButtonClick = () => {
                navigate('/projects/' + project.id + '/delivery');
            };
        } else if (statusKey === 'installation_to_plan') {
            primaryLabel = `installation.actions_card_buttons.plan_installation_date`;

            onPrimaryButtonClick = () => {
                setOpen(true);
            };
        } else if (statusKey === 'installation_planned') {
            primaryLabel = `installation.actions_card_buttons.modify_installation_date`;
            onPrimaryButtonClick = () => {
                setOpen(true);
            };
        } else if (statusKey === 'waiting_for_pre_installation_form') {
            primaryLabel = 'installation.actions_card_buttons.complete_pre_installation_form';

            onPrimaryButtonClick = () => {
                navigate('/projects/' + project.id + '/pre-installation');
            };

            secondaryLabel = 'installation.actions_card_buttons.modify_installation_date';
            onSecondaryButtonClick = () => {
                setOpen(true);
            };
        } else if (statusKey === 'ready_to_install') {
            primaryLabel = 'installation.actions_card_buttons.start_installation';
            onPrimaryButtonClick = () => {
                navigate('/projects/' + project.id + '/sign-off-sheet');
            };
        }
        primaryRightArrow = true;
        title = (
            <Stack>
                <Typography level="h4">{t(`installation.actions_card_title.${statusKey}`)}</Typography>
                {project.installation && project.installation.startDate && (
                    <Typography level="h4">
                        {formatDate(
                            project.installation.startDate,
                            false,
                            true,
                            getUserLang(),
                            projectState.project?.address.countryCode,
                        )}
                    </Typography>
                )}
            </Stack>
        );
        subtitle = (
            <Stack>
                <Typography level="body-lg">{project.address.street1}</Typography>
                <Typography level="body-lg">
                    {project.address.postalCode}&nbsp;{project.address.city}
                </Typography>
            </Stack>
        );
    }

    // all the following project status values are for the installation done status
    else if (
        [
            'waiting_for_legal_documents_upload',
            'waiting_for_billing_upload',
            'waiting_for_billing_validation',
            'project_completed',
        ].find((e) => e === statusKey)
    ) {
        title = (
            <Stack>
                <Typography level="h4">{t('installation.actions_card_title.installation_done')}</Typography>
                {project.installation && project.installation.startDate && (
                    <Typography level="h4">
                        {formatDate(
                            project.installation.startDate,
                            false,
                            true,
                            getUserLang(),
                            projectState.project?.address.countryCode,
                        )}
                    </Typography>
                )}
            </Stack>
        );

        primaryLabel = 'installation.actions_card_buttons.download_sign_off_sheet';

        if (project.installation?.installationHandoverToClient.clientHasReserves) {
            color = 'danger';
            subtitle = 'installation.actions_card_subtitle.installation_done_with_reserve';
        } else {
            subtitle = 'installation.actions_card_subtitle.installation_done';
            color = 'primary';
        }

        onPrimaryButtonClick = () => {
            navigate('/projects/' + project.id + '/sign-off-sheet');
        };
        onSecondaryButtonClick = () => {
            const currentUrl = window.location.pathname;
            const urlSegments = currentUrl.split('/');
            urlSegments.pop();

            urlSegments.push('pre-installation');
            const newUrl = urlSegments.join('/');
            navigate(newUrl);
        };
        secondaryLabel = 'installation.actions_card_buttons.download_pre_installation_sheet';
        // }
    } else {
        return;
    }

    return (
        <>
            <TwoActionsCard
                onPrimaryButtonClick={onPrimaryButtonClick}
                onSecondaryButtonClick={onSecondaryButtonClick}
                primaryActionDataTestId={primaryActionDataTestId}
                secondaryActionDataTestId={secondaryActionDataTestId}
                primaryLabel={primaryLabel}
                secondaryLabel={secondaryLabel}
                title={title}
                subtitle={subtitle}
                primaryRightArrow={primaryRightArrow}
                secondaryRightArrow={secondaryRightArrow}
                color={color}
            />
            <DateModal open={open} setOpen={setOpen} projectId={project.id} entity="installation" askEndDate={true} />
            <Toaster text={snackbarText} color="danger" open={snackbarOpen} setOpen={setSnackbarOpen} />
        </>
    );
}
