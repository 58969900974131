import { Project } from 'src/core/project/domain';
import TwoActionsCard from '../TwoActionsCard';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/joy';
import { ReactNode, useContext, useState } from 'react';
import DateModal from '../modal/DateModal';
import formatDate from '../../../utils/formatDate';
import Toaster from '../Toaster';
import { useNavigate } from 'react-router-dom';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import downloadFile from 'src/utils/downloadFile';
import { BackendDataSourceContext } from 'src/ui/contexts/backend-data-source-context';
import { getUserLang } from 'src/ui/i18n';
import { ProjectsContext } from 'src/ui/contexts/project-context';

export default function TechnicalActionsCard({ project }: { project: Project }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarText] = useState<string>(t('survey.file_not_found'));
    const { authenticatedCall } = useAppAuth();
    const navigate = useNavigate();
    const dataSourceBackend = useContext(BackendDataSourceContext)!;
    const { projectState } = useContext(ProjectsContext)!;

    // production : https://installer.chargeguru.com
    const inProduction = window.location.hostname && window.location.hostname === 'installer.chargeguru.com';

    let primaryLabel: string | undefined;
    let downloadLabel: string | undefined;
    let secondaryLabel: string | undefined;
    let title: string | ReactNode | undefined;
    let subtitle: string | ReactNode | undefined;
    let onPrimaryButtonClick: Function | undefined;
    let onDownloadButtonClick: Function | undefined;
    let onSecondaryButtonClick: Function | undefined = () => {};
    let primaryRightArrow: boolean | undefined;
    let secondaryRightArrow: boolean | undefined;
    let primaryActionDataTestId: string = 'primary-action';
    let secondaryActionDataTestId: string = 'secondaryAction';
    // let color: 'primary' | 'danger' | undefined;
    const pdfKeyArray = project.survey?.pdfKey?.split(',');

    // qar url for production by default
    let qarUrl =
        'https://qar.chargeguru.com/inputDate.php?opportunityId=' + project.reference + '&lang=' + getUserLang();
    // tous les autres
    if (!inProduction) {
        qarUrl =
            'https://staging-qar.chargeguru.com/inputDate.php?opportunityId=' +
            project.reference +
            '&sandbox=integ&lang=' +
            getUserLang();
    }

    if (project.status === 'project_accepted') {
        const mandatory = project.surveyMandatory;
        onSecondaryButtonClick = undefined;
        secondaryLabel = `survey.plan_${mandatory ? 'mandatory' : 'non_mandatory'}_manual_action`;
        title = `survey.plan_${mandatory ? 'mandatory' : 'non_mandatory'}_title`;
        subtitle = `survey.plan_${mandatory ? 'mandatory' : 'non_mandatory'}_subtitle`;

        if (mandatory) {
            // standard path
            primaryLabel = 'survey.plan_mandatory_action';
            onPrimaryButtonClick = () => {
                window.open(qarUrl, '_blank');
            };
            primaryRightArrow = true;
            primaryActionDataTestId = 'plan_survey';
        } else {
            secondaryRightArrow = true;
            secondaryActionDataTestId = 'plan_survey';
            onSecondaryButtonClick = () => {
                window.open(qarUrl, '_blank');
            };
        }
    } else if (project.status === 'survey_planned') {
        onPrimaryButtonClick = () => {
            navigate('survey');
        };
        primaryActionDataTestId = 'start_survey';

        onSecondaryButtonClick = () => {
            window.open(qarUrl, '_blank');
        };
        secondaryActionDataTestId = 'plan_survey';

        title = (
            <Stack>
                <Typography level="h4">{t('survey.planned_title')}</Typography>
                {/* {project.survey && project.survey.startDate && (
                    <Typography level="h4">{formatDate(project.survey.startDate, false, true)}</Typography>
                )} */}
            </Stack>
        );
        subtitle = (
            <Stack>
                <Typography level="body-lg">{project.address.street1}</Typography>
                <Typography level="body-lg">
                    {project.address.postalCode}&nbsp;{project.address.city}
                </Typography>
            </Stack>
        );
        secondaryLabel = 'survey.planned_modify_action';
        primaryRightArrow = true;
        primaryLabel = 'survey.planned_action';
    } else if (
        !['project_proposed', 'project_rejected', 'project_accepted', 'survey_planned'].includes(project.status)
    ) {
        onDownloadButtonClick = (pdfKey: string) => {
            authenticatedCall(async (accessToken) => {
                downloadFile(project, pdfKey, pdfKey, dataSourceBackend, accessToken);
            });
        };
        onPrimaryButtonClick = () => {
            navigate('survey');
        };
        onSecondaryButtonClick = undefined;
        title = (
            <Stack>
                <Typography level="h4">{t('survey.conducted')}</Typography>
            </Stack>
        );
        subtitle = t('survey.conducted_date', {
            date: formatDate(
                project.survey?.startDate,
                false,
                true,
                getUserLang(),
                projectState.project?.address.countryCode,
            ),
        });
        primaryLabel = 'survey.add_files';
        downloadLabel = 'survey.file,survey.see_file';
        primaryActionDataTestId = 'download_survey_pdf';
        // color = 'secondary';
    } else {
        return;
    }
    return (
        <>
            {pdfKeyArray &&
                pdfKeyArray.map((pdfKey, index) => (
                    <TwoActionsCard
                        key={index}
                        onPrimaryButtonClick={() => onDownloadButtonClick!(pdfKey)}
                        primaryActionDataTestId={primaryActionDataTestId}
                        primaryLabel={downloadLabel?.split(',')[0] + `:${index + 1}:` + downloadLabel?.split(',')[1]}
                        title={index !== 0 ? null : title}
                        subtitle={index !== 0 ? null : subtitle}
                        primaryRightArrow={primaryRightArrow}
                    />
                ))}
            <TwoActionsCard
                onPrimaryButtonClick={onPrimaryButtonClick}
                onSecondaryButtonClick={onSecondaryButtonClick}
                primaryActionDataTestId={primaryActionDataTestId}
                secondaryActionDataTestId={secondaryActionDataTestId}
                primaryLabel={primaryLabel}
                secondaryLabel={secondaryLabel}
                title={pdfKeyArray ? null : title}
                subtitle={pdfKeyArray ? null : subtitle}
                primaryRightArrow={primaryRightArrow}
                secondaryRightArrow={secondaryRightArrow}
            />
            <DateModal open={open} setOpen={setOpen} projectId={project.id} entity="survey" askEndDate={false} />
            <Toaster text={snackbarText} color="danger" open={snackbarOpen} setOpen={setSnackbarOpen} />
        </>
    );
}
