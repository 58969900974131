import { Box, Card, Stack, Typography } from '@mui/joy';
import { Project, getClient, getClientDisplayName } from 'src/core/project/domain';
import { useTranslation } from 'react-i18next';
import { ReactNode, useContext, useState } from 'react';
import ProjectStatusTag from '../../atoms/ProjectStatusTag';
import TypologyIcon from '../TypologyIcon';
import formatDate from 'src/utils/formatDate';
import ProgressBar from '../ProgressBar';
import LifeCycleActionCard from '../LifeCycleActionCard';
import { ProjectsContext } from '../../contexts/project-context';
import { getPercentageForProjectStatus } from 'src/core/project/domain';
import { projectActions } from 'src/core/project/service';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import DateModal from '../modal/DateModal';
import Toaster from '../Toaster';
import getStatusKey from '../../../utils/getStatusKey';
import { useNavigate } from 'react-router-dom';
import formatHours from 'src/utils/formatHours';
import FlashIconBox from 'src/ui/atoms/icons/FlashIconBox';
import { getUserLang } from 'src/ui/i18n';

interface InfoLineProps {
    center?: boolean;
    label: string;
    dataTestId?: string;
    value: string | ReactNode;
    icon?: JSX.Element;
}

function InfoLine({ center, label, value, dataTestId, icon }: InfoLineProps) {
    const { t } = useTranslation();

    return (
        <Stack direction="row" alignItems={center ? 'center' : 'auto'}>
            <Typography color="grey" level="title-sm" sx={{ width: '120px' }}>
                {t(label)}
            </Typography>
            {typeof value === 'string' ? (
                <>
                    {typeof icon === 'object' && icon !== null ? (
                        <Stack direction="row" spacing={1} alignItems="center">
                            {icon}
                            <Typography data-test-id={dataTestId} level="title-sm">
                                {value}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography data-test-id={dataTestId} level="title-sm">
                            {value}
                        </Typography>
                    )}
                </>
            ) : (
                <>{value}</>
            )}
        </Stack>
    );
}

interface InputProps {
    project: Project;
}

export default function ProjectOverviewCard({ project }: InputProps) {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarText] = useState<string>(t('survey.error_on_form_link'));
    const { authenticatedCall } = useAppAuth();
    const navigate = useNavigate();
    const { dispatch, projectState } = useContext(ProjectsContext)!;

    // production : https://installer.chargeguru.com
    const inProduction = window.location.hostname && window.location.hostname === 'installer.chargeguru.com';

    let lifeCycleSubtitle: string | ReactNode;
    let lifeCycleSecondTitle: string | undefined;
    let lifeCycleTitle: string | undefined;
    let primaryLabel: string | undefined;

    if (!project.surveyMandatory && project.status === 'project_accepted' && !project.extraCostSurvey) {
        lifeCycleTitle = t(`projects.lifecycle.card_title.${project.status}_IQ_send`, {
            survey_date: formatDate(
                project.survey?.startDate,
                false,
                false,
                getUserLang(),
                projectState.project?.address.countryCode,
            ),
            hour: formatHours(new Date(project.survey?.startDate!), t, false),
        });
        lifeCycleSecondTitle = `projects.lifecycle.card_title.${project.status}_IQ_survey`;
        lifeCycleSubtitle = `projects.lifecycle.card_subtitle.${project.status}_IQ_survey`;
        primaryLabel = `projects.lifecycle.card_primary_action.${project.status}_IQ`;
    } else {
        lifeCycleTitle = t(`projects.lifecycle.card_title.${project.status}`, {
            survey_date: formatDate(
                project.survey?.startDate,
                false,
                false,
                getUserLang(),
                projectState.project?.address.countryCode,
            ),
            hour: formatHours(new Date(project.survey?.startDate!), t, false),
        });
        lifeCycleSubtitle = `projects.lifecycle.card_subtitle.${project.status}`;
        primaryLabel = `projects.lifecycle.card_primary_action.${project.status}`;
    }
    let onAccept;
    let onDecline: (() => void) | undefined = undefined;
    let onOtherPrimaryClick: (() => void) | undefined = undefined;
    let otherPrimaryLabel: string | undefined = undefined;
    let statusKey = getStatusKey(project);
    let actionDataTestId: string | undefined = `${statusKey}_action`;
    let otherActionDataTestId: string | undefined = `${statusKey}_action`;
    let entity: 'survey' | 'installation' = 'survey';
    let askEndDate = false;
    let showCard: boolean = true;
    // qar url for production by default
    let qarUrl =
        'https://qar.chargeguru.com/inputDate.php?opportunityId=' + project.reference + '&lang=' + getUserLang();
    // tous les autres
    if (!inProduction) {
        qarUrl =
            'https://staging-qar.chargeguru.com/inputDate.php?opportunityId=' +
            project.reference +
            '&sandbox=integ&lang=' +
            getUserLang();
    }

    switch (project.status) {
        case 'project_proposed':
            if (project.remoteSurvey) {
                onAccept = () =>
                    authenticatedCall(async (accessToken) => {
                        // Dispatch the action to accept the project
                        dispatch(projectActions.accept(accessToken, project.id));
                        dispatch(projectActions.updateStatus(accessToken, project.id, 'survey_conducted'));
                    });

                onDecline = () =>
                    authenticatedCall(async (accessToken) => dispatch(projectActions.refuse(accessToken, project.id)));
                break;
            } else {
                onAccept = () =>
                    authenticatedCall(async (accessToken) => dispatch(projectActions.accept(accessToken, project.id)));
                onDecline = () =>
                    authenticatedCall(async (accessToken) => dispatch(projectActions.refuse(accessToken, project.id)));
                break;
            }

        case 'project_accepted':
            if (project.surveyMandatory) {
                // standard path
                primaryLabel = 'projects.lifecycle.card_primary_action.project_accepted';
                onAccept = () => {
                    window.open(qarUrl, '_blank');
                };
            } else {
                if (!project.extraCostSurvey) {
                    // IQ path
                    // set label of IQ quote send
                    primaryLabel = `projects.lifecycle.card_primary_action.IQ_send_quote_now`;

                    onAccept = () => {
                        navigate('quotes');
                    };

                    // set label of IQ survey to plan
                    otherPrimaryLabel = 'projects.lifecycle.card_primary_action.IQ_plan_survey_before_sending_quote';
                    onOtherPrimaryClick = () => {
                        window.open(qarUrl, '_blank');
                    };
                } else {
                    primaryLabel = `projects.lifecycle.card_primary_action.project_accepted`; // TO CHANGE
                    onAccept = () => {
                        window.open(qarUrl, '_blank');
                    };
                }
            }
            break;
        case 'survey_planned':
            onAccept = () => {
                navigate('survey');
                //if (project.survey && project.survey.formLink) {
                //    window.open(project.survey?.formLink, '_blank');
                //} else {
                //    setSnackbarOpen(true);
                //}
            };
            lifeCycleSubtitle = (
                <Stack>
                    <Typography level="body-lg">{project.address.street1}</Typography>
                    <Typography level="body-lg">{`${project.address.postalCode} ${project.address.city}`}</Typography>
                </Stack>
            );
            break;
        case 'survey_conducted':
            primaryLabel = `projects.lifecycle.card_primary_action.survey_conducted`;
            onAccept = () => {
                navigate('quotes');
            };
            break;
        case 'quote_proposed':
        case 'quote_validated':
            onAccept = () => {
                navigate('quotes');
            };
            break;
        case 'waiting_for_new_quote':
            onAccept = () => {
                navigate('quotes', { state: { showForm: true } });
            };
            break;
        case 'project_lost':
        case 'project_rejected':
            showCard = false;
            break;
        case 'delivery':
        case 'installation':
            entity = 'installation';
            askEndDate = true;
            primaryLabel = `projects.lifecycle.card_primary_action.${statusKey}`;

            lifeCycleSubtitle = (
                <Stack>
                    <Typography level="body-lg">{project.address.street1}</Typography>
                    <Typography level="body-lg">{`${project.address.postalCode} ${project.address.city}`}</Typography>
                </Stack>
            );

            if (statusKey === 'shipping_in_progress') {
                let showInstallerAddress: boolean = false;

                if (project.installerAddress) {
                    for (let delivery of project.deliveries) {
                        if (
                            delivery.address &&
                            project.installerAddress.street1 === delivery.address.street1 &&
                            project.installerAddress.postalCode === delivery.address.postalCode
                        ) {
                            showInstallerAddress = true;
                            break;
                        }
                    }
                }

                if (showInstallerAddress) {
                    lifeCycleSubtitle = (
                        <Stack>
                            <Typography level="body-lg">{project.installerAddress.street1}</Typography>
                            <Typography level="body-lg">{`${project.installerAddress.postalCode} ${project.address.city}`}</Typography>
                        </Stack>
                    );
                } else {
                    lifeCycleSubtitle = (
                        <Stack>
                            <Typography level="body-lg">
                                {t(`projects.lifecycle.card_subtitle.shipping_in_progress_to_client`)}
                            </Typography>
                        </Stack>
                    );
                }

                onAccept = () => {
                    navigate('delivery');
                };
            } else if (statusKey === 'installation_to_plan' || statusKey === 'installation_planned') {
                onAccept = () => {
                    setOpen(true); // open date modal
                };
            } else if (statusKey === 'waiting_for_pre_installation_form') {
                onAccept = () => {
                    navigate('pre-installation');
                };
            } else if (statusKey === 'ready_to_install') {
                // Classic project (NOT IQ project)
                onAccept = () => {
                    navigate('sign-off-sheet');
                };
            }
            if (new Date(project.installation?.startDate!).getUTCHours() !== 0) {
                lifeCycleTitle = t(`projects.lifecycle.card_title.${statusKey}`, {
                    installation_date: project.installation?.startDate
                        ? formatDate(
                              project.installation?.startDate,
                              false,
                              false,
                              getUserLang(),
                              projectState.project?.address.countryCode,
                          )
                        : '',
                    installation_hour: project.installation?.startDate
                        ? formatHours(new Date(project.installation?.startDate), t, false)
                        : '',
                });
            } else {
                lifeCycleTitle = t(`projects.lifecycle.card_title.${statusKey}_no_hour`, {
                    installation_date: project.installation?.startDate
                        ? formatDate(
                              project.installation?.startDate,
                              false,
                              false,
                              getUserLang(),
                              projectState.project?.address.countryCode,
                          )
                        : '',
                });
            }

            break;
        case 'waiting_for_legal_documents_upload':
            primaryLabel = `projects.lifecycle.card_primary_action.waiting_for_legal_documents_upload`;
            onAccept = () => {
                navigate('legal-documents', { state: { showForm: true } });
            };
            break;

        case 'waiting_for_billing_upload':
            onAccept = () => {
                navigate('billings', { state: { showForm: true } });
            };
            break;
        default:
    }

    if (project.status === 'project_accepted' && project.survey?.status === 'cancelled') {
        if (project.survey.cancellationReason === 'conflict') {
            primaryLabel = 'projects.lifecycle.card_primary_action.survey_cancelled';
            lifeCycleSecondTitle = `projects.lifecycle.card_title.survey_cancelled`;
            lifeCycleSubtitle = `projects.lifecycle.card_subtitle.survey_cancelled`;
        } else if (project.survey.cancellationReason === 'customer_not_reachable') {
            primaryLabel = 'projects.lifecycle.card_primary_action.customer_not_reachable';
            lifeCycleSecondTitle = `projects.lifecycle.card_title.customer_not_reachable`;
            lifeCycleSubtitle = `projects.lifecycle.card_subtitle.customer_not_reachable`;
        }
    }

    const client: any = getClient(project.status, project.client);
    const clientDisplayName: string = getClientDisplayName(
        client.clientType,
        client.clientName,
        client.clientFirstName,
        client.clientLastName,
        client.clientParentName,
    );
    return (
        <Card variant="plain" sx={{ marginTop: '10px' }}>
            <Typography level="h2" style={{ filter: client.toBlur ? 'blur(10px)' : '' }} data-test-id="project-name">
                {clientDisplayName}
            </Typography>
            <Stack alignItems="center" direction="row">
                <TypologyIcon marginRight="5px" typology={project.typology} />
                <Typography data-test-id="project-typology" level="body-lg">
                    {t(`projects.typology.${project.typology.replace(' ', '_')}`)}
                </Typography>
            </Stack>
            <InfoLine
                dataTestId="project-installation-type"
                label="projects.installation_type"
                value={t(`projects.type.${project.type?.toLowerCase().replaceAll(' ', '_')}`)}
            />
            {project.surveyMandatory ? (
                <InfoLine
                    dataTestId="project-quantification-type"
                    label="projects.quantification_type"
                    value={t('projects.estimate.to_quote')}
                />
            ) : (
                <InfoLine
                    dataTestId="project-quantification-type"
                    label="projects.quantification_type"
                    value={t('projects.estimate.instant_quote')}
                    icon={<FlashIconBox />}
                />
            )}

            {/* <InfoLine dataTestId="project-advenir" label="projects.advenir_prime" value="Oui" /> */}
            <InfoLine
                label="projects.technicalContact"
                value={
                    <Stack>
                        <Typography level="title-sm" data-test-id="project-technicalContact-name">
                            {`${project.technicalContact.firstName} ${project.technicalContact.lastName}`}
                        </Typography>
                        <Typography level="title-sm" data-test-id="project-technicalContact-phone">
                            {project.technicalContact.phone}
                        </Typography>
                        <Typography level="title-sm" data-test-id="project-technicalContact-email">
                            {project.technicalContact.email}
                        </Typography>
                    </Stack>
                }
            />
            <InfoLine
                label="projects.user"
                value={
                    <Stack>
                        <Typography level="title-sm">{project.user.email}</Typography>
                    </Stack>
                }
            />
            <InfoLine center label="projects.progress_status" value={<ProjectStatusTag project={project} />} />
            {showCard && (
                <>
                    <Box sx={{ marginTop: '10px' }} data-test-id="lifecycle-progress-bar">
                        <ProgressBar
                            percentage={getPercentageForProjectStatus(project.status).percentage}
                            label={`projects.lifecycle.progress_title.${project.status}`}
                        />
                    </Box>
                    <LifeCycleActionCard
                        actionDataTestId={actionDataTestId}
                        otherActionDataTestId={otherActionDataTestId}
                        declineDataTestId={`${project.status}_decline_lifecycle`}
                        title={lifeCycleTitle}
                        primaryActionLabel={primaryLabel}
                        secondaryActionLabel={`projects.lifecycle.card_secondary_action.${project.status}`}
                        subtitle={lifeCycleSubtitle}
                        secondTitle={lifeCycleSecondTitle}
                        onPrimaryClick={onAccept}
                        onSecondaryClick={onDecline}
                        onOtherPrimaryClick={onOtherPrimaryClick}
                        otherPrimaryActionLabel={otherPrimaryLabel}
                        optional={
                            !project.surveyMandatory &&
                            project.status === 'project_accepted' &&
                            !project.extraCostSurvey
                        }
                    />
                </>
            )}
            <DateModal open={open} setOpen={setOpen} projectId={project.id} entity={entity} askEndDate={askEndDate} />
            <Toaster color="danger" open={snackbarOpen} setOpen={setSnackbarOpen} text={snackbarText} />
        </Card>
    );
}
