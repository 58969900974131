import { Card, Grid, Stack, Typography } from '@mui/joy';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { Document, PurchaseOrder } from 'src/core/project/domain';
import { theme } from '../../theme';
import formatDate from 'src/utils/formatDate';
import { getUserLang } from '../../i18n';
import { ProjectsContext } from '../../contexts/project-context';
import { useContext } from 'react';
import { BackendDataSourceContext } from '../../contexts/backend-data-source-context';
import { useAppAuth } from 'src/ui/contexts/auth-context';
import DownloadIcon from 'src/ui/atoms/icons/Download';
import previewFile from 'src/utils/previewFile';
import { useNavigate } from 'react-router-dom';
import RightSecondaryArrowIcon from 'src/ui/atoms/icons/RightSecondaryArrowIcon';

export function PurchaseOrderResume({ item, purchaseOrder }: { item: Document; purchaseOrder: PurchaseOrder }) {
    const { t } = useTranslation();
    const { authenticatedCall } = useAppAuth();
    const { projectState } = useContext(ProjectsContext)!;
    const dataSourceBackend = useContext(BackendDataSourceContext)!;
    const navigate = useNavigate();

    const onClick = () => {
        const project = projectState.project;
        if (item && item.id) {
            authenticatedCall(async (accessToken) => {
                previewFile(project, item.fileKey, item.title, dataSourceBackend, accessToken);
            });
        }
    };

    return (
        <Card variant={'plain'} color="grey" sx={{ margin: 1, backgroundColor: theme.palette.grey[100] }}>
            <Stack spacing={1}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid xs="auto">
                        <Typography level="title-lg">Bon de commande :</Typography>
                        <Typography level="title-lg">{purchaseOrder.number}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid xs={6}>
                        <Typography level="body-md">{t('billing.date')}</Typography>
                        <Typography level="title-lg">
                            {formatDate(
                                purchaseOrder.date,
                                false,
                                false,
                                getUserLang(),
                                projectState.project?.address.countryCode,
                            )}
                        </Typography>
                    </Grid>
                    <Grid xs="auto">
                        <Typography level="body-md">{t('quote.amount_short')}</Typography>
                        <Typography level="title-lg">{purchaseOrder.amount} €</Typography>
                    </Grid>
                </Grid>
                <Button
                    label={t('billing.purchase_order.title')}
                    color="grey"
                    variant="outlined"
                    startDecorator={<DownloadIcon />}
                    onClick={onClick}
                />
                <Button
                    label={t(`billing.new_billing`)}
                    color="primary"
                    variant="solid"
                    endDecorator={<RightSecondaryArrowIcon />}
                    onClick={() => {
                        const currentUrl = window.location.pathname;
                        const urlSegments = currentUrl.split('/');
                        urlSegments.pop();

                        urlSegments.push('purchase-orders/' + item.parentEntityId + '/new-billings');
                        const newUrl = urlSegments.join('/');
                        navigate(newUrl);
                    }}
                />
            </Stack>
        </Card>
    );
}
