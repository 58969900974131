import { Box, Chip, Stack, Typography } from '@mui/joy';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Project, getClient, getClientDisplayName } from '../../../core/project/domain';
import VIPIcon from '../../atoms/icons/VIPIcon';
import ProjectStatusTag from '../../atoms/ProjectStatusTag';
import TypologyIcon from '../TypologyIcon';
import FlashIconBox from 'src/ui/atoms/icons/FlashIconBox';

import { useAppAuth } from 'src/ui/contexts/auth-context';
import UserIconBox from 'src/ui/atoms/icons/UserIcon';
import LocationIconBox from 'src/ui/atoms/icons/LocationIcon';

interface InputProps {
    project: Project;
}

export default function ProjectOverview({ project }: InputProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAppAuth();

    const opacity = useMemo(() => {
        switch (project.status) {
            case 'project_completed':
                return 0.6;
            case 'project_rejected':
                return 0.4;
            default:
                return 1;
        }
    }, [project.status]);

    const client: any = getClient(project.status, project.client);
    const clientDisplayName: string = getClientDisplayName(
        client.clientType,
        client.clientName,
        client.clientFirstName,
        client.clientLastName,
        client.clientParentName,
    );
    return (
        <Stack
            data-test-id="project-link"
            direction="row"
            sx={{ opacity: opacity, cursor: 'pointer' }}
            onClick={() => navigate(project.id)}
        >
            <TypologyIcon typology={project.typology} />
            <Stack direction="column">
                <Typography
                    level="body-sm"
                    color="grey"
                    data-test-id="project-typology"
                    data-test-project-name-value={project.client.name}
                >
                    {t(`projects.typology.${project.typology.replace(' ', '_')}`)}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                        level="title-lg"
                        style={{ filter: client.toBlur ? 'blur(5px)' : '' }}
                        data-test-id="project-name"
                        data-test-value={project.client.name}
                    >
                        {clientDisplayName}
                    </Typography>

                    {project.client.isVIP && (
                        <Box data-test-id="project-vip" data-test-project-name-value={project.client.name}>
                            <VIPIcon />
                        </Box>
                    )}
                </Stack>
                <Stack pb={1}>
                    {project.user.authId !== user?.sub ? (
                        <>
                            <Typography level="body-md">
                                {<LocationIconBox />} {project.address.street1} {project.address.postalCode}{' '}
                                {project.address.city}
                            </Typography>
                            <Typography level="body-md">
                                {<UserIconBox />} {project.user.email}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography
                                level="body-md"
                                data-test-id="project-street"
                                data-test-project-name-value={project.client.name}
                            >
                                {project.address.street1}
                            </Typography>
                            <Typography
                                level="body-md"
                                data-test-id="project-city"
                                data-test-project-name-value={project.client.name}
                            >
                                {project.address.postalCode} {project.address.city}
                            </Typography>
                        </>
                    )}
                </Stack>
                <ProjectStatusTag project={project} />
            </Stack>
            {!project.surveyMandatory ? (
                <Box sx={{ alignSelf: 'flex-start', ml: 'auto' }}>
                    <Chip
                        variant={'outlined'}
                        sx={{
                            padding: '5px 5px',
                            borderColor: 'lightgrey',
                            color: 'green',
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '5px',
                        }}
                    >
                        <Stack direction="row" alignItems="center" spacing={'5px'}>
                            <FlashIconBox />
                            <Typography level="body-sm">{t('instant_quote')}</Typography>
                        </Stack>
                    </Chip>
                </Box>
            ) : null}
        </Stack>
    );
}
