import { Project } from 'src/core/project/domain';
import { BackendApi } from 'src/providers/data-source-backend';

const previewFile = (
    project: Project | null,
    s3Key: string,
    title: string,
    dataSourceBackend: BackendApi,
    accessToken: string,
) => {
    const projectId = project ? project.id : '';
    dataSourceBackend.retrieveFileByS3Key(accessToken, projectId, s3Key).then(async (res) => {
        if (!res) {
            return;
        }
        if (!res?.includes(';base64,')) {
            throw new Error('File not found' + res);
        }
        const blob = await fetch(res).then((r) => r.blob());
        const url = URL.createObjectURL(blob);
        window.open(url);
    });
};

export default previewFile;
