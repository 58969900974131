import React, { useEffect, forwardRef, JSX, useId, useState, InputHTMLAttributes } from 'react';
import { styled } from '@mui/joy/styles';
import Input from '@mui/joy/Input';
import { Color, Variant } from 'src/ui/types/joy-ui';
import FormHelperText from '@mui/joy/FormHelperText';
import FormControl from '@mui/joy/FormControl';

const StyledInput = styled('input')({
    border: 'none', // remove the native input border
    minWidth: 0, // remove the native input width
    outline: 0, // remove the native input outline
    padding: 0, // remove the native input padding
    flex: 1,
    color: 'inherit',
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    textOverflow: 'ellipsis',
    '&::placeholder': {
        opacity: 0,
        transition: '0.1s ease-out',
    },
    '&:focus::placeholder': {
        opacity: 1,
    },
    '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
        top: '-0.5rem',
        fontSize: '0.75rem',
        backgroundColor: 'white',
        borderRadius: '30px',
        padding: '0px 6px',
        // zIndex: 1,
    },
    '&:focus ~ label': {
        color: 'var(--Input-focusedHighlight)',
    },
    '&:-webkit-autofill': {
        alignSelf: 'stretch', // to fill the height of the root slot
    },
    '&:-webkit-autofill:not(* + &)': {
        marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
        paddingInlineStart: 'var(--Input-paddingInline)',
        borderTopLeftRadius: 'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
        borderBottomLeftRadius: 'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    },
});

const StyledLabel = styled('label')(({ theme }) => ({
    position: 'absolute',
    lineHeight: 1,
    top: 'calc((var(--Input-minHeight) - 1em) / 2)',
    color: theme.vars.palette.text.tertiary,
    fontWeight: theme.vars.fontWeight.md,
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));

const InnerInput = forwardRef<HTMLInputElement, JSX.IntrinsicElements['input'] & { label: string }>(
    function InnerInput(props, ref) {
        const id = useId();

        return (
            <>
                <StyledInput {...props} ref={ref} id={id} />
                <StyledLabel htmlFor={id}>{props.label}</StyledLabel>
            </>
        );
    },
);

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    color?: Color;
    variant?: Variant;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    helperText?: string;
    isError?: boolean;
}

export default function FloatingLabelInput({
    color,
    label,
    placeholder,
    disabled,
    variant,
    helperText,
    isError,
    ...props
}: InputProps) {
    const [sx, setSx] = useState({});

    useEffect(() => {
        let sxToSet =
            color === 'secondary'
                ? {
                      '--Input-minHeight': '44px',
                      '--Input-radius': '6px',
                      borderColor: 'grey.200',
                      '&:hover': {
                          borderColor: 'grey.500',
                      },
                      ':not([data-skip-inverted-colors])': {
                          '--Input-focusedHighlight': 'grey.500',
                      },
                  }
                : {
                      '--Input-minHeight': '44px',
                      '--Input-radius': '6px',
                  };

        setSx(sxToSet);
    }, [color]);

    return (
        <FormControl error={isError}>
            <Input
                disabled={disabled}
                color={disabled ? 'grey' : color}
                variant={disabled ? 'solid' : variant}
                slots={{ input: InnerInput }}
                slotProps={{ input: { placeholder, label, ...props } }}
                sx={sx}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
